// App.js

import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { Input, Modal } from "antd";
import Home from "./containers/home";
import Transaction from "./containers/transaction";
import Calculator from "./containers/calculator";
import Settlement from "./containers/settlement";
import CircularSlider from "@fseehawer/react-circular-slider";
import "./dashboard.css";

export function Dashboard() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentNav, setCurrentNav] = useState("Home");
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [payValue, setPayValue] = useState(100);

  useEffect(() => {
    navigate(`/dashboard/${currentNav.toLowerCase()}`);
  }, [currentNav, navigate]);

  function componentRenderer(currentNav) {
    switch (currentNav) {
      case "Home":
        return <Home />;
      case "Transactions":
        return <Transaction />;
      case "Calculator":
        return <Calculator />;
      case "Settlements":
        return <Settlement />;
      default:
        return currentNav;
    }
  }

  function handleLogout() {
    localStorage.removeItem("token");
    navigate(`/`);
  }

  return (
    <>
      <Drawer
        title=""
        placement={"left"}
        width={300}
        open={open}
        onClose={() => setOpen(false)}
        className={"sksks"}
        closeIcon={false}
      >
        <div className="m-dash_nav">
          <div className="logo">
            <img
              src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/logo.png"
              alt="logo"
            />
          </div>
          <hr />
          {["Home", "Transactions", "Settlements", "Calculator"].map((ele) => (
            <div
              key={ele}
              className={`${
                currentNav === ele ? "mbnavItems active" : "mbnavItems"
              }`}
              onClick={() => {
                setCurrentNav(ele);
                setOpen(false);
              }}
            >
              <img
                src={`https://storage.googleapis.com/investroi-assets/LandingPageIcons/${
                  currentNav === ele
                    ? "active" + ele.toLowerCase()
                    : ele.toLowerCase()
                }.png`}
                alt={""}
              />
              <h4 className="m-navItem">
                {ele === "Home" ? "Dashboard" : ele}
              </h4>
            </div>
          ))}
        </div>
      </Drawer>
      <div className="m-dash_header">
        <div className="menu menu1" onClick={() => setOpen(!open)}>
          <img
            src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/menu.png"
            alt="nav"
          />
        </div>
        <div className="menu menu2">
          <button
            class="invest m-invest-b"
            onClick={() => setIsModelOpen(true)}
          >
            Invest Now
          </button>
        </div>
        <div className="menu menu2">
          <img
            src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/notification.png"
            alt="notfication"
          />
          <img
            src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/user_profile.png"
            alt="profile"
            onClick={handleLogout}
          />
        </div>
      </div>
      <Modal
        width={"90%"}
        title="Pay Now"
        centered={false}
        open={isModalOpen}
        onCancel={() => setIsModelOpen(false)}
        wrapClassName="m-investmentModel"
        footer={() => (
          <>
            <button
              className="modelCancel"
              onClick={() => {
                setIsModelOpen(false);
                setPayValue(9);
              }}
            >
              Cancel
            </button>
          </>
        )}
        closeIcon={false}
        destroyOnClose={true}
      >
        <div className="payment">
          <Input
            className="number"
            value={payValue}
            onChange={(e) => setPayValue(e.target.value.replace(/\D/g, ""))}
          />
          <div className="paymentButtons">
            {[9, 99, 999].map((i) => (
              <button onClick={() => setPayValue(i)}>{i}</button>
            ))}
          </div>
          <div>
            <a
              href={`upi://pay?pa=Q764042528@ybl&tn=TRNINVESTID${
                payValue * 654
              }&am=${payValue}&cu=INR&pn=chethan`}
            >
              <img
                className="paytm"
                src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/paytm.png"
                alt="paytm"
                srcset=""
              />
            </a>
            <a
              href={`phonepe://pay?pa=Q764042528@ybl&tn=TRNINVESTID${
                payValue * 654
              }&cu=INR`}
            >
              <img
                className="paytm"
                src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/phonepe.png"
                alt="PhonePe"
                srcset=""
              />
            </a>
          </div>
          <div>
            <CircularSlider
              label="Amount"
              width={200}
              labelColor="#0053d7"
              knobColor="#0053d7"
              progressColorFrom="#0053d7"
              progressColorTo="#0053d7"
              min={9}
              max={9999}
              knobPosition="right"
              valueFontSize="3rem"
              trackColor="#0053d7"
              trackDraggable={true}
              initialValue={100}
              onChange={(val) => setPayValue(val)}
              continuous={{
                enabled: true,
                clicks: 180,
                interval: payValue < 10 ? 11 : 10,
              }}
            />
          </div>
        </div>
      </Modal>
      <div className="m-dash-render">{componentRenderer(currentNav)}</div>
    </>
  );
}

export default Dashboard;
