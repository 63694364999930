import { useState } from "react";
import "./dashboardStyle.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import CircularSlider from "@fseehawer/react-circular-slider";
import { Tabs } from "antd";

function Calculator() {
  const [range, setRange] = useState(1);
  const [principal, setPrincipal] = useState(9);
  const [isDaily, setIsDaily] = useState(true);

  const annualInterestRate = 0.12;

  const calculateAmount = (principal, days) => {
    return (principal * annualInterestRate * days) / 365;
  };

  const calculateReturns = (days) => {
    if (!isDaily) return calculateAmount(principal, range);
    const t = new Array(+days).fill(0).map((i, idx, arr) => {
      return calculateAmount(principal, arr.length - idx);
    });
    return t.reduce((a, b) => a + b, 0);
  };

  const data = {
    labels: ["Investment", "Returns"],
    datasets: [
      {
        label: "InvestROI",
        data: [
          isDaily ? principal * range : principal,
          calculateReturns(range),
        ],
        backgroundColor: ["#0053d7", "#4f8f00"],
        hoverOffset: 100,
      },
    ],
  };
  const onChange = (key) => {
    setIsDaily(key);
    setPrincipal(key ? 100 : 5000);
  };

  const items = [
    {
      key: true,
      label: "Daily Investement",
    },
    {
      key: false,
      label: "One Time Investment",
    },
  ];

  return (
    <>
      <section id="calculator">
        <div className="full-width-container">
          <div className="content dashboard">
            <div className="discover-container">
              <div className="tab">
                <Tabs
                  defaultActiveKey={true}
                  items={items}
                  onChange={onChange}
                  hidden={false}
                  className="calculator-tabs-text"
                />
              </div>
              <div className="graph-field">
                <div className="slider">
                  <div className="discover-cards card1 calculator">
                    <div className="discover-text">
                      <div className="discover-text-header">Investment</div>
                      <div className="discover-text-content investment-text">
                        &#8377;&nbsp;
                        {(isDaily
                          ? principal * range
                          : principal
                        ).toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <div className="discover-cards card1 calculator">
                    <div className="discover-text">
                      <div className="discover-text-header">Interest</div>
                      <div className="discover-text-content investment-text">
                        &#8377;&nbsp;
                        {Number(calculateReturns(range)).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="discover-cards card1 calculator">
                    <div className="discover-text">
                      <div className="discover-text-header">Total</div>
                      <div className="discover-text-content investment-text">
                        &#8377;&nbsp;
                        {(isDaily
                          ? calculateReturns(range) + principal * range
                          : calculateReturns(range) + principal
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-graph">
                  <Pie
                    data={data}
                    height={400}
                    width={400}
                    options={{
                      layout: {
                        padding: {
                          top: 50,
                          bottom: 50,
                          left: 50,
                          right: 50,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="sliderknobe">
                {!isDaily && (
                  <CircularSlider
                    width={250}
                    label="Investment"
                    labelColor="#0053d7"
                    knobColor="#0053d7"
                    progressColorFrom="#0053d7"
                    progressColorTo="#0053d7"
                    min={999}
                    max={1000000}
                    initialValue={5000}
                    knobPosition="right"
                    valueFontSize="3rem"
                    trackColor="#0053d7"
                    trackDraggable={true}
                    onChange={(val) => setPrincipal(val)}
                    continuous={{
                      enabled: true,
                      clicks: 100,
                      interval: principal < 1000 ? 1001 : 1000,
                    }}
                  />
                )}
                {isDaily && (
                  <CircularSlider
                    width={250}
                    label="Investment"
                    labelColor="#0053d7"
                    knobColor="#0053d7"
                    progressColorFrom="#0053d7"
                    progressColorTo="#0053d7"
                    min={9}
                    max={9999}
                    initialValue={100}
                    knobPosition="right"
                    valueFontSize="4rem"
                    trackColor="#0053d7"
                    trackDraggable={true}
                    onChange={(val) => setPrincipal(val)}
                    continuous={{
                      enabled: true,
                      clicks: 100,
                      interval: principal < 50 ? 41 : 50,
                    }}
                  />
                )}
                <CircularSlider
                  label="Days"
                  width={250}
                  labelColor="#0053d7"
                  knobColor="#0053d7"
                  progressColorFrom="#0053d7"
                  progressColorTo="#0053d7"
                  min={5}
                  max={9999}
                  knobPosition="right"
                  valueFontSize="4rem"
                  trackColor="#0053d7"
                  trackDraggable={true}
                  initialValue={365}
                  onChange={(val) => setRange(val)}
                  continuous={{
                    enabled: true,
                    clicks: 180,
                    interval: 1,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Calculator;
