/* eslint-disable */
import "./style.css";
import CircularSlider from "@fseehawer/react-circular-slider";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";

export function Landing() {
  const navigate = useNavigate();
  const items = [
    {
      key: true,
      label: "Daily Investement",
    },
    {
      key: false,
      label: "One Time Investment",
    },
  ];

  const [principal, setPrincipal] = useState();
  const [range, setRange] = useState(9);
  const [isDaily, setIsDaily] = useState(true);

  const annualInterestRate = 0.12;

  const calculateAmount = (principal, days) => {
    return (principal * annualInterestRate * days) / 365;
  };

  const calculateReturns = (days) => {
    if (!isDaily) return calculateAmount(principal, range);
    const t = new Array(+days).fill(0).map((i, idx, arr) => {
      return calculateAmount(principal, arr.length - idx);
    });
    return t.reduce((a, b) => a + b, 0);
  };

  const onChange = (key) => {
    setIsDaily(key);
    setPrincipal(key ? 100 : 5000);
  };

  const data = {
    labels: ["Investment", "Returns"],
    datasets: [
      {
        label: "InvestROI",
        data: [
          isDaily ? principal * range : principal,
          calculateReturns(range),
        ],
        backgroundColor: ["#0053d7", "#4f8f00"],
        hoverOffset: 20,
      },
    ],
  };

  return (
    <>
      <div className="mob-header-container">
        <div className="mob-custom-header">
          <header>
            <div className="logo">
              <img
                src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/logo.png"
                alt="logo"
              />
            </div>
          </header>
        </div>

        <main>
          <div className="full-width-container main">
            <div className="content">
              <div className="image-container">
                <div className="banner-containers">
                  <img
                    src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/banner1.png"
                    alt="logo"
                    className="banner-image"
                  />
                </div>
                <div className="banner-containers card2">
                  <img
                    src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/banner2.png"
                    alt="logo"
                    className="banner-image2"
                  />
                  <div className="banner-texts">
                    <h5 className="banner-header">
                      {" "}
                      Invest 9 Rupees Daily & Earn 12% Annually
                    </h5>
                    <h6 className="banner-description">
                      Join InvestROI today and start your journey towards
                      financial freedom. Invest just 9 rupees daily and watch
                      your investment blossom into significant returns.
                      <button
                        className="invest"
                        onClick={() => navigate(`/dashboard/home`)}
                      >
                        Invest Now
                      </button>
                    </h6>
                  </div>
                </div>
                <div className="banner-containers"></div>
              </div>
            </div>
          </div>
          <div className="full-width-container">
            <div className="content  mob-invest-plan section2">
              <h5 className="mob-banner-texts">
                Effortless Investment in Three Simple Steps
              </h5>
              <div className="mob-steps">
                <div className="mob-step-counter investment">1</div>
                <div className="mob-step-content">
                  <div className="mob-step-content-header">
                    Easy Registration
                  </div>
                  <div className="mob-step-content-description">
                    Begin your investment journey by registering with your phone
                    number. Verify your identity with a simple OTP, ensuring
                    your account is secure and personalised.
                  </div>
                </div>
              </div>
              <div className="mob-steps mob-card2">
                <div className="mob-step-counter investment">2</div>
                <div className="mob-step-content card2">
                  <div className="mob-step-content-header">
                    Update Your Profile
                  </div>
                  <div className="mob-step-content-description">
                    Enter your bank details, PAN card, and Aadhar card
                    information. Our platform uses advanced encryption to
                    safeguard your personal information, making sure your data
                    is protected at all times.
                  </div>
                </div>
              </div>
              <div className="mob-steps">
                <div className="mob-step-counter startInvest investment">3</div>
                <div className="mob-step-content card3">
                  <div className="mob-step-content-header">Start Investing</div>
                  <div className="mob-step-content-description">
                    Start investing 9 Rupees daily. Our intuitive dashboard
                    allows you to track your investment growth in real-time.
                    With InvestROI, your small daily contributions can lead to
                    significant annual returns.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="full-width-container">
            <div className="content  mob-invest-plan discover">
              <h5 className="mob-banner-texts">
                Discover the benefits of investing with us
              </h5>
              <div className="mob-steps">
                <div className="container">
                  <div className="mob-step-counter investment mob-discover-image">
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/coverflow_easy.png"
                      className="mob-discover-card2"
                      alt="card2"
                    />
                  </div>
                  <div className="mob-step-content discover-card">
                    <div className="mob-step-content-header">Easy to use</div>
                    <div className="mob-step-content-description">
                      Our platform is designed with simplicity in mind.
                    </div>
                  </div>
                </div>
              </div>

              <div className="mob-steps">
                <div className="container bigCard">
                  <div className="mob-step-counter investment mob-discover-image">
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/coverflow_security.png"
                      className="mob-discover-card2"
                      alt="card2"
                    />
                  </div>
                  <div className="mob-step-content discover-card">
                    <div className="mob-step-content-header">Secure</div>
                    <div className="mob-step-content-description">
                      Security is our top priority. We employ state-of-the-art
                      security measures, including advanced encryption and
                      secure data storage, to protect your sensitive information
                      and transactions.
                    </div>
                  </div>
                </div>
              </div>

              <div className="mob-steps">
                <div className="container lastCard">
                  <div className="mob-step-counter investment mob-discover-image">
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/coverflow_dependable.png"
                      className="mob-discover-card2"
                      alt="card2"
                    />
                  </div>
                  <div className="mob-step-content discover-card">
                    <div className="mob-step-content-header">Reliable</div>
                    <div className="mob-step-content-description">
                      We investment plans designed to provide stable and
                      reliable returns.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="full-width-container">
            <div className="content  mob-invest-plan">
              <h5 className="mob-banner-texts">Interest Calculator</h5>
              <div className="tab">
                <Tabs
                  defaultActiveKey={true}
                  items={items}
                  onChange={onChange}
                  hidden={false}
                  className="calculator-tabs-text"
                />
              </div>
              <div className="mob-graph">
                <div className="mob-graph-view">
                  <Pie
                    data={data}
                    height={246}
                    width={246}
                    options={{
                      layout: {
                        padding: {
                          top: 25,
                          bottom: 25,
                          left: 25,
                          right: 25,
                        },
                      },
                    }}
                  />
                </div>

                <div className="mobCalculator">
                  <div className="discover-cards card1 calculator">
                    <div className="discover-text">
                      <div className="discover-text-header">Investment</div>
                      <div className="discover-text-content investment-text">
                        &#8377;&nbsp;{" "}
                        {(isDaily
                          ? principal * range
                          : principal
                        ).toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <div className="discover-cards card1 calculator">
                    <div className="discover-text">
                      <div className="discover-text-header">Interest</div>
                      <div className="discover-text-content investment-text">
                        &#8377;&nbsp;
                        {Number(calculateReturns(range)).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="discover-cards card1 calculator">
                    <div className="discover-text">
                      <div className="discover-text-header">Total</div>
                      <div className="discover-text-content investment-text">
                        &#8377;&nbsp;
                        {(isDaily
                          ? calculateReturns(range) + principal * range
                          : calculateReturns(range) + principal
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mob-slider">
                  {!isDaily && (
                    <CircularSlider
                      width={180}
                      label="Investment"
                      labelColor="#0053d7"
                      knobColor="#0053d7"
                      progressColorFrom="#0053d7"
                      progressColorTo="#0053d7"
                      min={999}
                      max={1000000}
                      initialValue={5000}
                      knobPosition="right"
                      valueFontSize="2rem"
                      trackColor="#0053d7"
                      trackDraggable={true}
                      onChange={(val) => setPrincipal(val)}
                      continuous={{
                        enabled: true,
                        clicks: 100,
                        interval: principal < 1000 ? 1001 : 1000,
                      }}
                    />
                  )}
                  {isDaily && (
                    <CircularSlider
                      width={180}
                      label="Investment"
                      labelColor="#0053d7"
                      knobColor="#0053d7"
                      valueFontSize="2rem"
                      progressColorFrom="#0053d7"
                      progressColorTo="#0053d7"
                      min={9}
                      max={9999}
                      initialValue={100}
                      knobPosition="right"
                      trackColor="#0053d7"
                      trackDraggable={true}
                      onChange={(val) => setPrincipal(val)}
                      continuous={{
                        enabled: true,
                        clicks: 100,
                        interval: principal < 50 ? 41 : 50,
                      }}
                    />
                  )}

                  <CircularSlider
                    width={180}
                    label="Days"
                    labelColor="#0053d7"
                    knobColor="#0053d7"
                    valueFontSize="2rem"
                    progressColorFrom="#0053d7"
                    progressColorTo="#0053d7"
                    min={5}
                    max={9999}
                    knobPosition="right"
                    trackColor="#0053d7"
                    trackDraggable={true}
                    initialValue={365}
                    onChange={(val) => setRange(val)}
                    continuous={{
                      enabled: true,
                      clicks: 180,
                      interval: 1,
                    }}
                  />
                </div>

                <div className="follow-mob">
                  <h5 className="mob-banner-texts">Follow us</h5>
                  <div className="mob-social-media">
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/instagram.png"
                      alt="instagram"
                    />
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/twitter.png"
                      alt="twiiter"
                    />
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/youtube.png"
                      alt="youtube"
                    />
                  </div>
                </div>

                <div className="mob-footer">
                  <div className="mob-terms">
                    <a href="">Contact Us</a> |<a href="">Terms & Conditions</a>
                    |<a href=""> Privacy Policy </a>
                  </div>
                  <div className="mob-copywrite">
                    © 2024. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Landing;
