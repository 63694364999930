// App.js

import React, { useState, useEffect } from "react";
import "./login.css";
import { OtpScreen } from "../../components/Otp";
import Urls from "../../helpers/constants/string";

export function Login() {
  const [showOtp, setShowOtp] = useState(false);
  const [imageUrl, setImageUrl] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimate(true);
      setTimeout(() => {
        setImageUrl((imageUrl) => (imageUrl === 0 ? 1 : 0));
        setAnimate(false);
      }, 600); // Duration of the fade-out animation
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="login_container">
        <div className="login_subContainer">
          <img
            className={`login_image ${animate ? "animate" : ""}`}
            src={Urls.IMAGE_URL[imageUrl]}
            alt=""
          />
        </div>
        <div className="login_subContainer">
          <div className="login_form">
            <div className="login_label">
              <h2>Login to your account</h2>
            </div>
            <div className="login_details">
              <input
                type="tel"
                className="login_textInput"
                placeholder="Enter Phone Number"
              />
              <button className="primary" onClick={() => setShowOtp(true)}>
                Login
              </button>
              <OtpScreen show={showOtp} setShowOtp={setShowOtp} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
