import "./dashboardStyle.css";
import "chart.js/auto";
import { Tag } from "antd";

function Settlement() {
  const annualInterestRate = 0.12;
  const daysInYear = 365;
  const dailyInterestRate = annualInterestRate / daysInYear;

  const calculateAmount = (principal, days) => {
    return principal * Math.pow(1 + dailyInterestRate, days);
  };

  const date = new Date("2023-12-31");
  const sample = new Array(10).fill(1).map((_, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: idx,
      date: date.toDateString(),
      amount: 9 * (idx + 1),
      days: 50 - idx,
    };
  });

  const dataSource = sample.map((i, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: i.key,
      date: i.date,
      amount: i.amount,
      days: i.days,
      slNo: idx + 1,
      return: (calculateAmount(i.amount, i.days) - i.amount).toLocaleString(),
      total: calculateAmount(i.amount, i.days).toLocaleString(),
      status:
        idx === 0 ? (
          <Tag color="blue">PENDING</Tag>
        ) : (
          <Tag color="green">SETTLED</Tag>
        ),
    };
  });

  return (
    <>
      <div className="mm-homeContainer">
        {dataSource.map((i, idx) => {
          return (
            <div className="m-s">
              <div className="m-num">{idx + 1}</div>
              <div className="m-card-details">
                <div className="m-card-date">
                  <div className="m-card-s">{i.status}</div>
                  <div className="m-card-d">{i.date}</div>
                </div>
                <div className="m-card-return m-settle">
                  <div className="m-i m-i-settle">
                    <p>Investment</p>
                    <p>Returns</p>
                    <p>Total</p>
                  </div>
                  <div className="m-i">
                    <p className="m-i-b">{i.amount}</p>
                    <p className="m-i-b">{i.return}</p>
                    <p className="m-i-b">{i.total}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Settlement;
