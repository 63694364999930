/* eslint-disable */
import { useState } from "react";
import "./dashboardStyle.css";
import "chart.js/auto";
import { Table, Tag } from "antd";
import CircularSlider from "@fseehawer/react-circular-slider";

function Transaction() {
  const [range, setRange] = useState(1);

  const handleRange = (value) => {
    setRange(value);
  };

  const annualInterestRate = 0.12;
  const daysInYear = 365;
  const dailyInterestRate = annualInterestRate / daysInYear;

  const calculateAmount = (principal, days) => {
    return principal * Math.pow(1 + dailyInterestRate, days);
  };

  const date = new Date("2023-12-31");

  const sample = new Array(4).fill(1).map((_, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: idx,
      date: date.toDateString(),
      amount: 9 * (idx + 1),
      days: 50 - idx,
    };
  });

  const dataSource = sample.map((i, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: i.key,
      slno: idx + 1,
      transactionDate: i.date,
      amount: i.amount,
      name: "Tejas R",
      days: i.days + +range,
      utr: `UTR${i.days}`,
      transactionNote: "Test",
      paymentState: idx % (2 + 1) === 0 ? "COMPLETED" : "PENDING",
      return: (
        calculateAmount(i.amount, i.days + +range) - i.amount
      ).toLocaleString(),
      total: calculateAmount(i.amount, i.days + +range).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ),
    };
  });
  return (
    <>
      <div className="mm-homeContainer">
        <div className="m-transactionTable">
          {dataSource.map((i, idx) => {
            return (
              <div className="m-s">
                <div className="m-num">{idx + 1}</div>
                <div className="m-card-details">
                  <div className="m-card-date">
                    <div className="m-card-s">
                      <Tag color="green">Completed</Tag>
                    </div>
                    <div className="m-card-d">{i.transactionDate}</div>
                  </div>
                  <div className="m-card-return">
                    <div className="m-i">
                      <p>Investment</p>
                      <p>Returns</p>
                      <p>Total</p>
                    </div>
                    <div className="m-i">
                      <p className="m-i-b">{i.amount}</p>
                      <p className="m-i-b">{i.return}</p>
                      <p className="m-i-b">{i.total}</p>
                    </div>
                  </div>
                  <div className="m-card-wu">
                    <div className="utr">Paste UTR</div>
                    <div className="utr utr-g">Withdraw</div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="knob">
            <CircularSlider
              width={120}
              label="Days"
              labelColor="#0053d7"
              knobColor="#0053d7"
              progressColorFrom="#0053d7"
              progressColorTo="#0053d7"
              min={9}
              max={9999}
              initialValue={9}
              knobPosition="right"
              valueFontSize="2rem"
              trackColor="#0053d7"
              trackDraggable={true}
              onChange={handleRange}
              continuous={{
                enabled: true,
                clicks: 100,
                interval: 1,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Transaction;
