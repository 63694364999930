/* eslint-disable */
import "./style.css";
import CircularSlider from "@fseehawer/react-circular-slider";
import { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

export function Landing() {
  const items = [
    {
      key: true,
      label: "Daily Investement",
    },
    {
      key: false,
      label: "One Time Investment",
    },
  ];
  const navigate = useNavigate();
  const [principal, setPrincipal] = useState();
  const [range, setRange] = useState(9);
  const [isDaily, setIsDaily] = useState(true);

  const annualInterestRate = 0.12;

  const calculateAmount = (principal, days) => {
    return (principal * annualInterestRate * days) / 365;
  };

  const handleLoginSuccess = (credentialResponse) => {
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token);
    console.log("User info:", decoded, credentialResponse);
  };

  const handleLoginFailure = () => {
    console.log("Login failed");
  };

  const calculateReturns = (days) => {
    if (!isDaily) return calculateAmount(principal, range);
    const t = new Array(+days).fill(0).map((i, idx, arr) => {
      return calculateAmount(principal, arr.length - idx);
    });
    return t.reduce((a, b) => a + b, 0);
  };

  const data = {
    labels: ["Investment", "Returns"],
    datasets: [
      {
        label: "InvestROI",
        data: [
          isDaily ? principal * range : principal,
          calculateReturns(range),
        ],
        backgroundColor: ["#0053d7", "#4f8f00"],
        hoverOffset: 100,
      },
    ],
  };

  const onChange = (key) => {
    setIsDaily(key);
    setPrincipal(key ? 100 : 5000);
  };

  return (
    <>
      <div className="header-container">
        <header>
          <div className="logo">
            <img
              src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/logo.png"
              alt="logo"
            />
          </div>
          <div className="navigation-links">
            <a href="/business-solutions/">Home</a>
            <a href="/business-solutions/">Features</a>
            <a href="#calculator">Calculator</a>
          </div>
        </header>
      </div>
      <main>
        <div className="full-width-container main">
          <div className="content">
            <div className="image-container">
              <div className="banner-container">
                <img
                  src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/banner1.png"
                  alt="logo"
                  className="banner-image"
                />
              </div>
              <div className="text-container">
                <h1 className="text">
                  Invest 9 Rupees Daily & Earn 12% Annually
                </h1>
                <h1 className="text-description">
                  Join InvestROI today and start your journey towards financial
                  freedom. Invest just 9 rupees daily and watch your investment
                  blossom into significant returns.
                  <div className="investGoogle">
                    <GoogleLogin
                      className="invest"
                      onSuccess={handleLoginSuccess}
                      onError={handleLoginFailure}
                      size="large"
                      width={100}
                    />
                  </div>
                </h1>
              </div>
              <div className="banner-container">
                <img
                  src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/banner2.png"
                  className="banner-image2"
                  alt="banner"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="full-width-container">
          <div className="content invest-plan">
            <div className="steps">
              <div className="banner-texts">
                <h1>Effortless Investment in Three Simple Steps</h1>
              </div>
              <div className="step">
                <div className="step-counter register">1</div>
                <div className="step-content content1">
                  <div className="step-content-header">Easy Registration</div>
                  <div className="step-content-description">
                    Begin your investment journey by registering with your phone
                    number. Verify your identity with a simple OTP, ensuring
                    your account is secure and personalised.
                  </div>
                </div>
              </div>

              <div className="step reverse">
                <div className="step-counter couter-reverse">2</div>
                <div className="step-content content2">
                  <div className="step-content-header">Update Your Profile</div>
                  <div className="step-content-description">
                    Enter your bank details, PAN card, and Aadhar card
                    information. Our platform uses advanced encryption to
                    safeguard your personal information, making sure your data
                    is protected at all times.
                  </div>
                </div>
              </div>

              <div className="step">
                <div className="step-counter investment">3</div>
                <div className="step-content content3">
                  <div className="step-content-header">Start Investing</div>
                  <div className="step-content-description">
                    Start investing 9 Rupees daily. Our intuitive dashboard
                    allows you to track your investment growth in real-time.
                    With InvestROI, your small daily contributions can lead to
                    significant annual returns.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width-container">
          <div className="content discover">
            <div className="discover-container">
              <div className="text">
                <h1>Discover the benefits of investing with us</h1>
              </div>
              <div className="discover-content">
                <div className="discover-cards card1">
                  <div className="discover-card-image">
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/coverflow_easy.png"
                      alt="cover"
                    />
                  </div>
                  <div className="discover-text">
                    <div className="discover-text-header">Easy to use</div>
                    <div className="discover-text-content">
                      Our platform is designed with simplicity in mind.
                    </div>
                  </div>
                </div>
                <div className="discover-cards card2 center">
                  <div className="discover-card-image">
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/coverflow_security.png"
                      className="card2"
                      alt="card2"
                    />
                  </div>
                  <div className="discover-text">
                    <div className="discover-text-header">Secure</div>
                    <div className="discover-text-content">
                      Security is our top priority. We employ state-of-the-art
                      security measures, including advanced encryption and
                      secure data storage, to protect your sensitive information
                      and transactions.
                    </div>
                  </div>
                </div>
                <div className="discover-cards card3">
                  <div className="discover-card-image">
                    <img
                      alt="coverflow"
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/coverflow_dependable.png"
                    />
                  </div>
                  <div className="discover-text">
                    <div className="discover-text-header">Reliable</div>
                    <div className="discover-text-content">
                      We investment plans designed to provide stable and
                      reliable returns.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="calculator">
          <div className="full-width-container">
            <div className="content">
              <div className="discover-container">
                <div className="text">
                  <h1>Interest Calculator</h1>
                </div>
                <div className="tab">
                  <Tabs
                    defaultActiveKey={true}
                    items={items}
                    onChange={onChange}
                    hidden={false}
                    className="calculator-tabs-text"
                  />
                </div>
                <div className="graph-field">
                  <div className="slider">
                    <div className="discover-cards card1 calculator">
                      <div className="discover-text">
                        <div className="discover-text-header">Investment</div>
                        <div className="discover-text-content investment-text">
                          &#8377;&nbsp;
                          {(isDaily
                            ? principal * range
                            : principal
                          ).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="discover-cards card1 calculator">
                      <div className="discover-text">
                        <div className="discover-text-header">Interest</div>
                        <div className="discover-text-content investment-text">
                          &#8377;&nbsp;
                          {Number(calculateReturns(range)).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="discover-cards card1 calculator">
                      <div className="discover-text">
                        <div className="discover-text-header">Total</div>
                        <div className="discover-text-content investment-text">
                          &#8377;&nbsp;
                          {(isDaily
                            ? calculateReturns(range) + principal * range
                            : calculateReturns(range) + principal
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="slider-graph">
                    <Pie
                      data={data}
                      height={400}
                      width={400}
                      options={{
                        layout: {
                          padding: {
                            top: 50,
                            bottom: 50,
                            left: 50,
                            right: 50,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="sliderknobe">
                  {!isDaily && (
                    <CircularSlider
                      label="Investment"
                      labelColor="#0053d7"
                      knobColor="#0053d7"
                      progressColorFrom="#0053d7"
                      progressColorTo="#0053d7"
                      min={999}
                      max={1000000}
                      initialValue={5000}
                      knobPosition="right"
                      valueFontSize="4rem"
                      trackColor="#0053d7"
                      trackDraggable={true}
                      onChange={(val) => setPrincipal(val)}
                      continuous={{
                        enabled: true,
                        clicks: 100,
                        interval: principal < 1000 ? 1001 : 1000,
                      }}
                    />
                  )}
                  {isDaily && (
                    <CircularSlider
                      label="Investment"
                      labelColor="#0053d7"
                      knobColor="#0053d7"
                      progressColorFrom="#0053d7"
                      progressColorTo="#0053d7"
                      min={9}
                      max={9999}
                      initialValue={100}
                      knobPosition="right"
                      valueFontSize="4rem"
                      trackColor="#0053d7"
                      trackDraggable={true}
                      onChange={(val) => setPrincipal(val)}
                      continuous={{
                        enabled: true,
                        clicks: 100,
                        interval: principal < 50 ? 41 : 50,
                      }}
                    />
                  )}
                  <CircularSlider
                    label="Days"
                    labelColor="#0053d7"
                    knobColor="#0053d7"
                    progressColorFrom="#0053d7"
                    progressColorTo="#0053d7"
                    min={5}
                    max={9999}
                    knobPosition="right"
                    valueFontSize="4rem"
                    trackColor="#0053d7"
                    trackDraggable={true}
                    initialValue={365}
                    onChange={(val) => setRange(val)}
                    continuous={{
                      enabled: true,
                      clicks: 180,
                      interval: 1,
                    }}
                  />
                </div>
                <div className="follow">
                  <div className="text">
                    <h1>Follow Us</h1>
                  </div>
                  <div className="social-media">
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/instagram.png"
                      alt="instagram"
                    />
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/twitter.png"
                      alt="twiiter"
                    />
                    <img
                      src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/youtube.png"
                      alt="youtube"
                    />
                  </div>
                </div>
                <div className="footer">
                  <div className="copywrite">© 2024. All rights reserved.</div>
                  <div className="terms">
                    <a href="">Contact Us</a> |<a href="">Terms & Conditions</a>
                    |<a href=""> Privacy Policy </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Landing;
