import React, { useState, useEffect } from "react";
import Config from "./../helpers/config/default";
import Constants from "./../helpers/constants/string";
import "./style.css";

export function ComponentRenderer({ Desktop, Mobile }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    window.addEventListener(Constants.RESIZE, handleWindowSizeChange);
    return () => {
      window.removeEventListener(Constants.RESIZE, handleWindowSizeChange);
    };
  }, []);

  return <>{width <= Config.MOBILE_VIEW_WIDTH ? <Mobile /> : <Desktop />}</>;
}

export default ComponentRenderer;
