import { useState } from "react";
import "./dashboardStyle.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import CircularSlider from "@fseehawer/react-circular-slider";

function Home() {
  const [range, setRange] = useState(1);

  const annualInterestRate = 0.12;
  const daysInYear = 365;
  const dailyInterestRate = annualInterestRate / daysInYear;
  const principal = 54000;

  const calculateAmount = (principal, days) => {
    return principal * Math.pow(1 + dailyInterestRate, days);
  };

  const handleRange = (val) => {
    setRange(val);
  };

  const data = {
    labels: ["Investment", "Returns"],
    datasets: [
      {
        label: "InvestROI",
        data: [principal, calculateAmount(principal, range) - principal],
        backgroundColor: ["#0053d7", "#4f8f00"],
        hoverOffset: 100,
      },
    ],
  };

  return (
    <>
      <div className="m-homeContainer">
        <div className="m-home_headers">
          <div className="m-plans">
            <span>Total Investments</span>
            <span className="text">₹ {principal.toLocaleString()}</span>
          </div>
          <div className="m-plans">
            <span>Total Returns</span>
            <span className="text">
              ₹
              {(calculateAmount(principal, range) - principal).toLocaleString()}
            </span>
          </div>
          <div className="m-plans">
            <span>Current Balance</span>
            <span className="text">
              {" "}
              ₹ {calculateAmount(principal, range).toLocaleString()}
            </span>
          </div>
        </div>
        <div className="m-lineGraph">
          <div className="mob-graph-view{">
            <Pie
              data={data}
              height={246}
              width={246}
              options={{
                layout: {
                  padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  },
                },
              }}
            />
          </div>

          <CircularSlider
            width={250}
            label="Days"
            labelColor="#0053d7"
            knobColor="#0053d7"
            progressColorFrom="#0053d7"
            progressColorTo="#0053d7"
            min={9}
            max={9999}
            initialValue={9}
            knobPosition="right"
            valueFontSize="2rem"
            trackColor="#0053d7"
            trackDraggable={true}
            onChange={handleRange}
            continuous={{
              enabled: true,
              clicks: 100,
              interval: 1,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
