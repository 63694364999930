import "./dashboardStyle.css";
import "chart.js/auto";
import { Table, Tag } from "antd";

function Settlement() {
  const annualInterestRate = 0.12;
  const daysInYear = 365;
  const dailyInterestRate = annualInterestRate / daysInYear;

  const calculateAmount = (principal, days) => {
    return principal * Math.pow(1 + dailyInterestRate, days);
  };

  const date = new Date("2023-12-31");
  const sample = new Array(10).fill(1).map((_, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: idx,
      date: date.toDateString(),
      amount: 9 * (idx + 1),
      days: 50 - idx,
    };
  });

  const dataSource = sample.map((i, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: i.key,
      date: i.date,
      amount: i.amount,
      days: i.days,
      slNo: idx + 1,
      return: (calculateAmount(i.amount, i.days) - i.amount).toLocaleString(),
      total: calculateAmount(i.amount, i.days).toLocaleString(),
      status:
        idx === 0 ? (
          <Tag color="blue">PENDING</Tag>
        ) : (
          <Tag color="green">SETTLED</Tag>
        ),
    };
  });

  const columns = [
    {
      title: "SlNo",
      dataIndex: "slNo",
      key: "slNo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Returns",
      dataIndex: "return",
      key: "return",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <>
      <div className="homeContainer">
        <div className="settlementTable">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      </div>
    </>
  );
}

export default Settlement;
