import { Desktop, Mobile } from "./router";
import { ComponentRenderer } from "./hooks";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <>
      <GoogleOAuthProvider
        clientId={
          "971659522958-c28m0pvgb4g6gf2ik4mtlh85cp5s9i04.apps.googleusercontent.com"
        }
      >
        <ComponentRenderer Desktop={Desktop} Mobile={Mobile} />;
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
