/* eslint-disable */
import { useState } from "react";
import "./dashboardStyle.css";
import "chart.js/auto";
import { Table, Tag } from "antd";
import CircularSlider from "@fseehawer/react-circular-slider";

function Transaction() {
  const [range, setRange] = useState(1);

  const handleRange = (value) => {
    setRange(value);
  };

  const annualInterestRate = 0.12;
  const daysInYear = 365;
  const dailyInterestRate = annualInterestRate / daysInYear;

  const calculateAmount = (principal, days) => {
    return principal * Math.pow(1 + dailyInterestRate, days);
  };

  const date = new Date("2023-12-31");

  const sample = new Array(8).fill(1).map((_, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: idx,
      date: date.toDateString(),
      amount: 9 * (idx + 1),
      days: 50 - idx,
    };
  });

  const dataSource = sample.map((i, idx) => {
    date.setDate(date.getDate() + 1);
    return {
      key: i.key,
      slno: idx + 1,
      transactionDate: i.date,
      amount: i.amount,
      name: "Tejas R",
      days: i.days + +range,
      utr: `UTR${i.days}`,
      transactionNote: "Test",
      paymentState: idx % (2 + 1) === 0 ? "COMPLETED" : "PENDING",
      return: (
        calculateAmount(i.amount, i.days + +range) - i.amount
      ).toLocaleString(),
      total: calculateAmount(i.amount, i.days + +range).toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ),
    };
  });

  const columns = [
    {
      title: "Investement",
      dataIndex: "slno",
      key: "slno",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "TransactionDate",
      dataIndex: "transactionDate",
      key: "transactionDate",
    },
    {
      title: "UTR",
      dataIndex: "utr",
      key: "utr",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      dataIndex: "paymentState",
      key: "paymentState",
      render: (text, _record, _index) => (
        <Tag color={text === "COMPLETED" ? "green" : "blue"}>{text}</Tag>
      ),
    },

    {
      title: "Withdraw",
      dataIndex: "paymentState",
      key: "paymentState",
      render: (text, _record, _index) => (
        <button className="withdrawButton">{"Withdraw"}</button>
      ),
    },
  ];

  return (
    <>
      <div className="homeContainer">
        <div className="transactionTable">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
          <div className="knob">
            <CircularSlider
              width={120}
              label="Days"
              labelColor="#0053d7"
              knobColor="#0053d7"
              progressColorFrom="#0053d7"
              progressColorTo="#0053d7"
              min={9}
              max={9999}
              initialValue={9}
              knobPosition="right"
              valueFontSize="2rem"
              trackColor="#0053d7"
              trackDraggable={true}
              onChange={handleRange}
              continuous={{
                enabled: true,
                clicks: 100,
                interval: 1,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Transaction;
