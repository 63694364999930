import { useState } from "react";
import "./dashboardStyle.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import CircularSlider from "@fseehawer/react-circular-slider";

function Home() {
  const [range, setRange] = useState(1);

  const annualInterestRate = 0.12;
  const daysInYear = 365;
  const dailyInterestRate = annualInterestRate / daysInYear;
  const principal = 54000;

  const calculateAmount = (principal, days) => {
    return principal * Math.pow(1 + dailyInterestRate, days);
  };

  const handleRange = (val) => {
    setRange(val);
  };

  const data = {
    labels: ["Investment", "Returns"],
    datasets: [
      {
        label: "InvestROI",
        data: [principal, calculateAmount(principal, range) - principal],
        backgroundColor: ["#0053d7", "#4f8f00"],
        hoverOffset: 100,
      },
    ],
  };

  return (
    <>
      <div className="homeContainer">
        <div className="home_headers">
          <div className="plans">
            <span>Total Investments</span>
            <span className="text">₹ {principal.toLocaleString()}</span>
          </div>
          <div className="plans">
            <span>Total Returns</span>
            <span className="text">
              ₹
              {(calculateAmount(principal, range) - principal).toLocaleString()}
            </span>
          </div>
          <div className="plans">
            <span>Current Balance</span>
            <span className="text">
              {" "}
              ₹ {calculateAmount(principal, range).toLocaleString()}
            </span>
          </div>
        </div>
        <div className="lineGraph">
          <Pie
            data={data}
            height={300}
            width={300}
            options={{
              layout: {
                padding: {
                  top: 50,
                  bottom: 50,
                  left: 50,
                  right: 50,
                },
              },
            }}
          />
          <CircularSlider
            label="Days"
            labelColor="#0053d7"
            knobColor="#0053d7"
            progressColorFrom="#0053d7"
            progressColorTo="#0053d7"
            min={9}
            max={9999}
            initialValue={9}
            knobPosition="right"
            valueFontSize="4rem"
            trackColor="#0053d7"
            trackDraggable={true}
            onChange={handleRange}
            continuous={{
              enabled: true,
              clicks: 100,
              interval: 1,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
