import React, { useState, useEffect } from "react";
import "./login.css";
import { MobileOtpScreen } from "./../../../components/MobileOtp";
import Urls from "../../../helpers/constants/string";

export function Login() {
  const [showOtp, setShowOtp] = useState(false);
  const [imageUrl, setImageUrl] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimate(true);
      setTimeout(() => {
        setImageUrl((imageUrl) => (imageUrl === 0 ? 1 : 0));
        setAnimate(false);
      }, 500); // Duration of the fade-out animation
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="mobContainer">
      <div className="mobsubcontainer">
        <div className="mosubContainer">
          <div className="mobContent moblogin">
            <h5>
              Start investing digital payments at your store using UPI on Daily,
              Weekly and Monthly. Anually 12% Interest rate, Register on
              InvestROI app now!.
            </h5>
            <hr />
            <div className="mobLoginForm">
              <h3>Login to your account</h3>
              <div>
                <label>Phone no.</label>
                <br />
                <input
                  className="mobInputText"
                  type="tel"
                  placeholder="Enter your Phone no."
                />
              </div>
              <button className="mobButton" onClick={() => setShowOtp(true)}>
                GET OTP
              </button>
            </div>
            <hr />
          </div>

          <div className="mobContent">
            <img
              src={Urls.IMAGE_URL[imageUrl]}
              className={`mobImage ${animate ? "animate" : ""}`}
              alt=""
            />
          </div>
        </div>
      </div>
      <MobileOtpScreen show={showOtp} setShowOtp={setShowOtp} />
    </div>
  );
}

export default Login;
