import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login, Dashboard, Landing } from "../pages/Mobile";

export function Mobile() {
  const PublicRouters = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to={"/"} />} />
          <>
            {["Home", "Transactions", "Calculator", "Settlements"].map(
              (route) => {
                return (
                  <Route
                    key={route}
                    path={`dashboard/${route.toLowerCase()}`}
                    element={<Dashboard currentNavBar={route} />}
                  />
                );
              }
            )}
          </>
        </Routes>
      </BrowserRouter>
    );
  };

  return <PublicRouters />;
}

export default Mobile;
