// App.js

import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Home from "./containers/home";
import Transaction from "./containers/transaction";
import Calculator from "./containers/calculator";
import Settlement from "./containers/settlement";
import { useNavigate } from "react-router-dom";
import { Input, Modal } from "antd";
import { QRCode } from "react-qrcode-logo";
import CircularSlider from "@fseehawer/react-circular-slider";

export function Dashboard({ currentNavBar = "Home" }) {
  const navigate = useNavigate();
  const [currentNav, setCurrentNav] = useState(currentNavBar);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [payValue, setPayValue] = useState(100);

  function handleLogout() {
    localStorage.removeItem("token");
    navigate(`/`);
  }

  useEffect(() => {
    navigate(`/dashboard/${currentNav.toLowerCase()}`);
  }, [currentNav, navigate]);

  function componentRenderer(currentNav) {
    switch (currentNav) {
      case "Home":
        return <Home />;
      case "Transactions":
        return <Transaction />;
      case "Calculator":
        return <Calculator />;
      case "Settlements":
        return <Settlement />;
      default:
        return currentNav;
    }
  }

  return (
    <div className="dashboardContainer">
      <div className="dashboardSideBard">
        <div className="dash_navItems">
          <div className="logo">
            <img
              src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/logo.png"
              alt="logo"
            />
          </div>
          {["Home", "Transactions", "Settlements", "Calculator"].map((ele) => (
            <div
              key={ele}
              className={`${
                currentNav === ele ? "navItems active" : "navItems"
              }`}
              onClick={() => setCurrentNav(ele)}
            >
              <img
                src={`https://storage.googleapis.com/investroi-assets/LandingPageIcons/${
                  currentNav === ele
                    ? "active" + ele.toLowerCase()
                    : ele.toLowerCase()
                }.png`}
                alt={""}
              />
              <h4 className="navItem">{ele === "Home" ? "Dashboard" : ele}</h4>
            </div>
          ))}
        </div>
      </div>

      <div className="dash_content">
        <div className="dash_content_header">
          <div className="invest_">
            <button className="invest" onClick={() => setIsModelOpen(true)}>
              Invest Now
            </button>
          </div>
          <div className="dash_left_profile">
            <div className="notification">
              <img
                src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/notification.png"
                alt="notfication"
              />
            </div>
            <div className="profile" onClick={handleLogout}>
              <img
                src="https://storage.googleapis.com/investroi-assets/LandingPageIcons/user_profile.png"
                alt="profile"
              />
            </div>
          </div>
        </div>

        <Modal
          title="Pay Now"
          open={isModalOpen}
          onCancel={() => setIsModelOpen(false)}
          wrapClassName="investmentModel"
          footer={() => (
            <>
              <button
                className="modelCancel"
                onClick={() => {
                  setIsModelOpen(false);
                  setPayValue(9);
                }}
              >
                Cancel
              </button>
            </>
          )}
          closeIcon={false}
          destroyOnClose={true}
        >
          <div className="payment">
            <Input
              className="number"
              value={payValue}
              onChange={(e) => setPayValue(e.target.value.replace(/\D/g, ""))}
            />
            <div className="paymentButtons">
              {[9, 99, 999].map((i) => (
                <button onClick={() => setPayValue(i)}>{i}</button>
              ))}
            </div>
            <div>
              <QRCode
                size={180}
                value={`upi://pay?pa=Q764042528@ybl&tn=TRNINVESTID${
                  payValue * 654
                }&am=${payValue}`}
              />
            </div>
            <div>
              <CircularSlider
                label="Amount"
                width={200}
                labelColor="#0053d7"
                knobColor="#0053d7"
                progressColorFrom="#0053d7"
                progressColorTo="#0053d7"
                min={9}
                max={9999}
                knobPosition="right"
                valueFontSize="3rem"
                trackColor="#0053d7"
                trackDraggable={true}
                initialValue={100}
                onChange={(val) => setPayValue(val)}
                continuous={{
                  enabled: true,
                  clicks: 180,
                  interval: payValue < 10 ? 11 : 10,
                }}
              />
            </div>
          </div>
        </Modal>

        {componentRenderer(currentNav)}
      </div>
    </div>
  );
}

export default Dashboard;
